//***  OPEN MODAL YAPEE   ***//

const yapeModal = document.getElementById('modalYape');
yapeModal.style.display = 'none';

const openYapeModal = document.getElementById('btnYape');
openYapeModal.addEventListener('click', () => {
	yapeModal.style.display = 'flex';
});

const closeYapeModalBtn = document.getElementById(
	'closeYapeModal'
);
closeYapeModalBtn.addEventListener('click', () => {
	yapeModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === yapeModal) {
		yapeModal.style.display = 'none';
	}
});

//***  OPEN MODAL PLINN   ***//

const plinModal = document.getElementById('modalPlin');
plinModal.style.display = 'none';

const openPlinModal = document.getElementById('btnPlin');
openPlinModal.addEventListener('click', () => {
	plinModal.style.display = 'flex';
});

const closePlinModalBtn = document.getElementById(
	'closePlinModal'
);
closePlinModalBtn.addEventListener('click', () => {
	plinModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === plinModal) {
		plinModal.style.display = 'none';
	}
});

//***  OPEN MODAL BCP   ***//

const bcpModal = document.getElementById('modalBCP');
bcpModal.style.display = 'none';

const openBcpModal = document.getElementById('btnBcp');
openBcpModal.addEventListener('click', () => {
	bcpModal.style.display = 'flex';
});

const closeBcpModalBtn = document.getElementById(
	'closeBcpModal'
);
closeBcpModalBtn.addEventListener('click', () => {
	bcpModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === bcpModal) {
		bcpModal.style.display = 'none';
	}
});

//***  OPEN MODAL BBVA   ***//


const bbvaModal = document.getElementById('modalBbva');
bbvaModal.style.display = 'none';

const openBvaModal = document.getElementById('btnBbva');
openBvaModal.addEventListener('click', () => {
	bbvaModal.style.display = 'flex';
});

const closeBbvaModalBtn = document.getElementById(
	'closeBvaModal'
);
closeBbvaModalBtn.addEventListener('click', () => {
	bbvaModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === bbvaModal) {
		bbvaModal.style.display = 'none';
	}
});

//***  OPEN MODAL Interbank   ***//


const interbankModal = document.getElementById('modalInterbank');
interbankModal.style.display = 'none';

const openInterbankModal = document.getElementById('btnInterbank');
openInterbankModal.addEventListener('click', () => {
	interbankModal.style.display = 'flex';
});

const closeInterbankModalBtn = document.getElementById(
	'closeInterbankModal'
);
closeInterbankModalBtn.addEventListener('click', () => {
	interbankModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === interbankModal) {
		interbankModal.style.display = 'none';
	}
});



//***  OPEN MODAL SCOTIABANK   ***//


const scotiaModal = document.getElementById('modalScotia');
scotiaModal.style.display = 'none';

const openScotiaModal = document.getElementById('btnScotia');
openScotiaModal.addEventListener('click', () => {
	scotiaModal.style.display = 'flex';
});

const closeScotiaModalBtn = document.getElementById(
	'closeScotiaModal'
);
closeScotiaModalBtn.addEventListener('click', () => {
	scotiaModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === scotiaModal) {
		scotiaModal.style.display = 'none';
	}
});


//***  OPEN MODAL BANCO NACION   ***//


const nacionModal = document.getElementById('modalNacion');
nacionModal.style.display = 'none';

const openNacionModal = document.getElementById('btnNacion');
openNacionModal.addEventListener('click', () => {
	nacionModal.style.display = 'flex';
});

const closeNacionModalBtn = document.getElementById(
	'closeNacionModal'
);
closeNacionModalBtn.addEventListener('click', () => {
	nacionModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === nacionModal) {
		nacionModal.style.display = 'none';
	}
});




// const copyAccount = document.getElementById('copyAccount');
// copyAccount.addEventListener('click', () => {
// 	const account = '0022-0156-0200368345';
// 	navigator.clipboard
// 		.writeText(account)
// 		.then(function () {
// 			console.log('Texto copiado al portapapeles');
// 		})
// 		.catch(function (err) {
// 			console.error('Error al copiar texto: ', err);
// 		});
// });


// const copyCCI = document.getElementById('copyCCI');
// copyCCI.addEventListener('click', () => {
// 	const CCI = 'CCI: 011-101-0022-0156-0200368345';
// 	navigator.clipboard
// 		.writeText(CCI)
// 		.then(function () {
// 			console.log('Texto copiado al portapapeles');
// 		})
// 		.catch(function (err) {
// 			console.error('Error al copiar texto: ', err);
// 		});
// });


// // CONFIRM ASSISTANCE 

// const btnConfirmAsistance = document.getElementById('btnConfirmAsistance');

// btnConfirmAsistance.addEventListener("click", () => {
// 	let phoneNumber = '+17742932223';
// 	let encodedMessage = encodeURIComponent("Hola, confirmo mi asistencia a la boda...");

// 	const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
	
// 	window.open(url, '_blank');
// });


	  